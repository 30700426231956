import * as R from 'ramda'
import {StyledEngineProvider, ThemeProvider} from '@mui/material/styles'
import React, {useEffect, useState} from 'react'

import defaultTheme from 'config/theme'
import FooterFondation from 'components/Layout/FooterFondation'
import HeaderFondation from 'components/Layout/HeaderFondation'
import palette1 from 'config/palette1'
import Popper from 'components/UI/Popper'
import ScrollTop from 'components/UI/ScrollTopButton'
import useAosInit from 'hooks/useAosInit'
import useIsMobile from 'hooks/useIsMobile'
import useMountComponent from 'hooks/useMountComponent'

import useStyles from './styles'

const LayoutFondation = props => {
  const isMobile = useIsMobile()
  const {children, data, is404, layoutData, pageContext} = props
  const {nodeLocale} = pageContext
  const countryData = R.path(['contentfulCountry'], data)

  const classes = useStyles()

  const [showLayout, setShowLayout] = useState(false)
  const [showPopper, setShowPopper] = useState(false)
  const [popperLink, setPopperLink] = useState('')

  const isLayoutMounted = useMountComponent()
  const showNav = process.env.GATSBY_IN_SITE_MAINTENANCE === 'false'

  const localizedHeader = R.filter(
    el => el.node.node_locale === nodeLocale,
    layoutData.allContentfulHeader.edges,
  )

  const {redirectionPopperLabel, redirectionPopperCta1, redirectionPopperCta2} =
    !is404 && localizedHeader && R.pathOr(null, [0, 'node'], localizedHeader)

  const handleLayoutDisplay = bool =>
    bool
      ? {visibility: 'visible', marginTop: isMobile ? 25 : 100}
      : {visibility: 'hidden'}

  useEffect(() => {
    setShowLayout(isLayoutMounted)
  }, [isLayoutMounted])

  useAosInit(showLayout)

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={defaultTheme}>
        <ThemeProvider theme={palette1}>
          <Popper
            showPopper={showPopper}
            setShowPopper={() => setShowPopper(!showPopper)}
            link={popperLink}
            label={redirectionPopperLabel}
            cta1={redirectionPopperCta1}
            cta2={redirectionPopperCta2}
          />
          <div
            style={handleLayoutDisplay(showLayout)}
            className={classes.wrapper}
          >
            {showNav && !is404 && (
              <HeaderFondation
                pageContext={pageContext}
                countryData={countryData}
                data={layoutData}
                showPopper={showPopper}
                setShowPopper={setShowPopper}
                setPopperLink={setPopperLink}
              />
            )}
            {children}
            {showNav && !is404 && (
              <FooterFondation
                pageContext={pageContext}
                countryData={countryData}
                data={layoutData}
              />
            )}
            <ScrollTop />
          </div>
        </ThemeProvider>
      </ThemeProvider>
    </StyledEngineProvider>
  )
}

export default LayoutFondation
