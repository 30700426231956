import React from 'react'
import ReactDOM from 'react-dom'

import LayoutFondation from 'components/Layout/LayoutFondation'

import './src/styles/global.css'
import './static/fonts.css'
import 'bootstrap/dist/css/bootstrap.min.css'
import {useLayoutData} from './src/hooks/useLayoutData'

export function replaceHydrateFunction() {
  return (element, container, callback) => {
    ReactDOM.render(element, container, callback)
  }
}

export const shouldUpdateScroll = () => {
  if (window.location.href.indexOf('#') > -1) {
    return true
  }

  return [0, 0]
}

export const onPreRouteUpdate = () => {
  if (window.location.href.indexOf('#') > -1) {
    return true
  }

  return [0, 0]
}

export const wrapPageElement = ({element, props}) => {
  const {pageResources} = props
  const is404 = pageResources.page.path.includes('404')

  const Wrapper = () => {
    const layoutData = useLayoutData()

    return (
      <LayoutFondation is404={is404} layoutData={layoutData} {...props}>
        {element}
      </LayoutFondation>
    )
  }

  return <Wrapper />
}
