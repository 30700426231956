import * as R from 'ramda'
import {bool, func, node, shape, string} from 'prop-types'
import {Link} from 'gatsby'
import classNames from 'classnames'
import Fade from '@mui/material/Fade'
import React, {useEffect, useState} from 'react'

import {dynamicRouting} from 'helpers/utils'
import {pageContextPropTypes} from 'helpers/propTypes'
import CloseButton from 'components/UI/CloseButton'
import DropDownLink from 'components/Layout/DropDownLinkKm'

import Media from 'components/UI/Media'

import SwitchLanguageSmall from 'components/Layout/SwitchLanguageSmall'
import useIsMobile from 'hooks/useIsMobile'

import useStyles from './styles'

const Header = ({
  pageContext,
  countryData,
  data,
  showPopper,
  setShowPopper,
  setPopperLink,
}) => {
  const {nodeLocale, prefix, hasShortPath} = pageContext
  const {countryCode, technicalName, navLocale, isMultiLang} = countryData
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [isModalLangOpen, setIsModalLangOpen] = useState(false)

  const classes = useStyles()
  const isMobile = useIsMobile()

  useEffect(() => {
    document.body.style.overflowY =
      isModalOpen || isModalLangOpen ? 'scroll' : 'scroll'
  }, [isModalOpen, isModalLangOpen])

  function toggleSwitchLang(value) {
    setIsModalOpen(false)
    setIsModalLangOpen(value || !isModalLangOpen)
  }

  function openMenuMobile() {
    setIsModalOpen(true)
    setIsModalLangOpen(false)
  }

  const localizedHeader = data.allContentfulHeader.edges.filter(
    el => el.node.node_locale === nodeLocale,
  )

  const {logo, headerMenu} = R.pathOr(null, [0, 'node'], localizedHeader)

  const renderNavList = () => (
    <>
      {R.map(
        ({
          id,
          title,
          link,
          childMenu,
          isExternalLink,
          isOpenInNewWindow,
          isSameMenuItemForAllCountries,
          menuItemPerCountry,
        }) => (
          <DropDownLink
            menuItemPerCountry={menuItemPerCountry}
            country={technicalName}
            menuTitle={title}
            menuLink={link}
            childMenu={childMenu}
            key={`${id}`}
            isItAnExternalLink={isExternalLink}
            shouldThisLinkOpenInANewBrowserWindow={isOpenInNewWindow}
            isSameMenuItemForAllCountries={isSameMenuItemForAllCountries}
            prefix={prefix}
            hasShortPath={hasShortPath}
            countryCode={countryCode}
            navLocale={navLocale}
            setShowPopper={() => setShowPopper(true)}
            togglePopper={() => setShowPopper(!showPopper)}
            setPopperLink={val => setPopperLink(val)}
          />
        ),
        headerMenu.menuItems,
      )}
      <div
        className={classNames(classes.bridgeHover, {
          [classes.bridgeHoverShape]: isModalLangOpen,
        })}
      >
        <button
          onClick={() => toggleSwitchLang()}
          type="button"
          className={classes.switchLanguageButton}
        >
          {R.toUpper(countryCode)}
          {!isMobile && isModalLangOpen && (
            <SwitchLanguageSmall
              currentCountry={countryCode}
              allSitePage={data.allSitePage.distinct}
            />
          )}
          {isMobile && (
            <SwitchLanguageSmall
              currentCountry={countryCode}
              allSitePage={data.allSitePage.distinct}
            />
          )}
        </button>
      </div>
    </>
  )

  const renderMobileNav = () => (
    <div className={classes.renderMobileNav}>
      <div>
        <nav role="navigation" className={classes.menu}>
          <Link
            to={dynamicRouting(
              prefix,
              'home',
              countryCode,
              hasShortPath,
              navLocale,
            )}
            // className={classNames(classes.logoKeyrus)}
          >
            <Media data={logo} className={classes.logoKeyrus} />
          </Link>
          <div style={{display: 'flex'}}>
            <button
              aria-label="open menu"
              label="open menu"
              type="button"
              onClick={() => openMenuMobile()}
              className={classes.hamburger}
            />
          </div>
          {isModalOpen && isMobile && (
            <>
              <div className={classes.bgModal} />
              <Fade appear={false} in={isModalOpen}>
                <aside className={classes.modal}>
                  <ul className={classes.navigation}>
                    {isMobile && (
                      <>
                        <CloseButton action={setIsModalOpen} />

                        <Link
                          to={dynamicRouting(
                            prefix,
                            'home',
                            countryCode,
                            hasShortPath,
                            navLocale,
                          )}
                          className={classNames(
                            classes.logoKeyrus,
                            classes.logoKeyrusModal,
                          )}
                        >
                          <Media data={logo} />
                        </Link>
                        <div className={classes.scrollable}>
                          {renderNavList()}
                        </div>
                      </>
                    )}
                  </ul>
                </aside>
              </Fade>
            </>
          )}
        </nav>
      </div>
    </div>
  )

  const renderDeskopNav = () => (
    <div className={classes.renderDeskopNav}>
      <div className={classes.subContainer}>
        <nav role="navigation" className={classes.menu}>
          <Link
            to={dynamicRouting(
              prefix,
              'home',
              countryCode,
              hasShortPath,
              navLocale,
            )}
          >
            <Media className={classes.logoKeyrus} data={logo} />
          </Link>
          <ul className={classes.navigation}>{renderNavList()}</ul>
        </nav>
      </div>
    </div>
  )

  return (
    <header className={classes.container}>
      {isMobile ? renderMobileNav() : renderDeskopNav()}
    </header>
  )
}

Header.propTypes = {
  countryData: shape({
    countryCode: string,
    technicalName: string,
    urlPrefix: string,
    navLocale: string,
    isMultiLang: bool,
  }),
  data: node,
  pageContext: pageContextPropTypes,
  setPopperLink: func,
  setShowPopper: func,
  showPopper: bool,
}

Header.defaultProps = shape({
  configSlug: null,
  contentId: '',
  menuItemsQuery: null,
  setPopperLink: null,
  setShowPopper: null,
  showPopper: false,
})

export default Header
